import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { map } from 'rxjs/operators';
import { EventCategory } from '@models/event-categories/event-category.model';
import { SaveEventCategoryRequest } from '@models/event-categories/dto/save-event-category.request';

@Injectable()
export class EventCategoriesService {
  constructor(private api: ApiService) {}

  getEventCategories(
    mainOnly = false,
    withTemplatesOnly = false,
  ): Observable<EventCategory[]> {
    const params = new Map<string, string>();
    if (mainOnly) {
      params.set('mainOnly', String(mainOnly));
    }
    if (withTemplatesOnly) {
      params.set('withTemplatesOnly', String(withTemplatesOnly));
    }

    return this.api
      .get(`${env.api}/event-categories`, params)
      .pipe(map((it) => this.mapEventCategories(it)));
  }

  private mapEventCategories(res: EventCategory[]) {
    return res.map((it) => new EventCategory(it));
  }

  saveEventCategory(req: SaveEventCategoryRequest): Observable<EventCategory> {
    return this.api
      .post(`${env.api}/event-categories`, req)
      .pipe(map((it) => new EventCategory(it)));
  }
}
